import "fontsource-playfair-display" // Defaults to weight 400 with all styles included.
import "fontsource-homemade-apple"
import React from "react"
import "./layout.css"

export default function Layout({ children }) {
  return (
    <div>
      {children}
    </div>
  )
}