import React, {useCallback, useState, useEffect} from "react"
import Character from "../components/character"
import Canvas from "../components/canvas"
import "fontsource-playfair-display" // Defaults to weight 400 with all styles included.
import "fontsource-homemade-apple"
import Layout from "../components/layout"
import { Helmet } from "react-helmet"


// https://speckyboy.com/css-javascript-text-animation-snippets/

export default function Home() {
  // const [paintStroke, setPaintStroke] = useState(true);
  // const onEnterIntro = useCallback(() => {
  //   setPaintStroke(false);
  //   setTimeout(() => {
  //     setPaintStroke(true);
  //   }, 0);
  // }, [])

  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <style type="text/css">
          @import url('https://fonts.googleapis.com/css?family=Homemade+Apple');
          @import url('https://fonts.googleapis.com/css?family=Playfair+Display');
        </style>
        <title>jenning chen</title>
        {/* <link rel="canonical" href="http://jenningchen.com/" /> */}
      </Helmet>
      <div className="nav">
        jenning chen
      </div>
      <svg className="intro" viewBox="0 0 200 58">
          <text textAnchor="start" x="0" y="35" className="text text-stroke" clipPath="url(#text1)">hey</text>
          <text textAnchor="start" x="0" y="55" className="text text-stroke" clipPath="url(#text2)">there.</text>
          <text textAnchor="start" x="0" y="35" className="text text-stroke text-stroke-2" clipPath="url(#text1)">hey</text>
          <text textAnchor="start" x="0" y="55" className="text text-stroke text-stroke-2" clipPath="url(#text2)">there.</text>
          <defs>
            <style type="text/css">
              @import url('https://fonts.googleapis.com/css?family=Playfair+Display');
            </style>
            <clipPath id="text1">
              <text textAnchor="start" x="0" y="35" className="text">hey</text>
            </clipPath>
            <clipPath id="text2">
              <text textAnchor="start" x="0" y="55" className="text">there.</text>
            </clipPath>
          </defs> 
      </svg>
      <br />
      <div className="bio fadeInRight">
        <hr style={{width: "30%", textAlign: "left", marginLeft: "0", marginBottom: "16px"}}/>
        I'm a software engineer interested in projects intersecting with tech, design, or sustainability.
        By day, I'm a builder and collaborator; by night, I'm a doodler and amateur photographer.
        <br />
        {/* Currently at <a href={"https://www.figma.com" } target="_blank">Figma</a>. */}
        
        <ul className="links">
          <li><a href={"https://www.linkedin.com/in/jenningchen/"} target="_blank">linkedin</a></li>
          <li><a href={"mailto:jenningnchen@gmail.com"}>email</a></li>
        </ul>
      </div>
    </Layout>
  )
} 
